import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"

import "../../../styles/pages/sunglasses.scss"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import GlassesHeading from "../../../components/GlassesHeading"
import Breadcrumbs from "../../../components/Breadcrumbs"
import GlassesFilter from "../../../components/GlassesFilter"
import GlassesList from "../../../components/GlassesList"
import ProductBanner from "../../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../../services/hooks"
import CartContext from "../../../context/cartContext"

const Sunglasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString, sunglassesAvailable }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern") &&
        sunglassesAvailable
      )
    }
  )

  const right_side_text =
  "When it comes to sunglasses, there's nothing quite like a classic rectangular frame to elevate your look. At Hubble, we offer a wide range of trendy options and fresh takes on timeless designs to suit any style. Whether you're looking for a sleek and sophisticated look or a bold and daring statement, our selection of handcrafted rectangular sunglasses has got you covered. Shop now and complete your look with a new favorite pair.<br><br>Quality and comfort are embedded into every pair of sunglasses from Hubble. Plus, you can always add your prescription to any sunglasses you choose. All of our rectangular frame sunglasses come with impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush. Look good, see better—and feel great."
  const list_items = [
  "Premium, hand-polished acetate frames sourced from natural fibers. Every pair of our high-quality sunglasses feature impact-resistant lenses for maximum durability and are designed to deliver supreme comfort.",
  "Option to add your prescription to all sunglasses and enjoy both vision clarity and comfort all in one piece of eyewear.",
  "Rectangle sunglasses all come with polarized lenses that block 99% of UVA/UVB rays and eliminate daytime glare.",
  "Affordable polarized sunglasses lenses.",
  "Hand-assembled in house and made to order.",
  ]


  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: ["rectangle"],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setHeaderColor("green")

    return () => {
      setHeaderColor("black")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'sunglasses',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Sunglasses-By-Frame-Rectangle`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])


  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/shop-sunglasses-by-frame-shape/rectangle-frame-glasses",
      "@type": "webpage",
      "name": "Shop the Best Rectangle Frame Glasses and Sunglasses | Hubble",
      "description": "Find the best glasses and sunglasses with rectangle frames. We making finding your perfect pair of eyeglasses easy, with our comprehensive collection of rectangular frames.",
      "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
      }
      }

  ]
  return (
    <Layout>
        <SEO
        title="Shop the Best Rectangle Frame Sunglasses"
        description="Find the best sunglasses with rectangle frames. We making finding your perfect pair of eyeglasses easy, with our comprehensive collection of rectangular frames."
        schemaMarkup={schemaMarkup}
        />
        <div className="sunglasses-content">
        <GlassesHeading
        color="green"
        title="Shop Rectangle Sunglasses"
        description="From classically vintage to delightfully modern, shop rectangular frame
          sunglasses from Hubble, starting at just $59.99."
        />


        <div className="glasses-container">
          <div className="grid-section">
            <Breadcrumbs
              color="green"
              links={[{ to: "/", label: "Homepage" }, { label: "Sunglasses" }]}
            />
            <GlassesFilter
              type="sunglasses"
              glassesLength={sortedGlasses.length}
              filter={filter}
              setFilter={setFilter}
            />
            <GlassesList
              type="sunglasses"
              glasses={sortedGlasses}
              color={filter.color}
              gender={filter.gender[0]}
            />
            <ProductBanner
            title={"Polarized Rectangular Sunglasses from Hubble."}
            right_side_text={right_side_text}
              list_items={list_items}
              optional_image="Hubble-Sunglasses-PCP-Inset-Lifestyle-Image.jpg"
              color="green"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sunglasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        sunglassesMaleHeaderImage1
        sunglassesFemaleHeaderImage1
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
